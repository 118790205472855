
import InfoPage from "@/components/InfoPage"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage as InfoPageId,
} from "@evercam/shared/types"
import { useNuxtApp } from "#app"

export default {
  components: {
    InfoPage,
  },
  middleware: "infoPagesRedirect",
  meta: {
    public: true,
  },
  mounted() {
    const infoPageIdsAnalyticsEventPageIdsMap = {
      [InfoPageId.BIMView]: AnalyticsEventPageId.BimInfo,
      [InfoPageId.DroneView]: AnalyticsEventPageId.DroneInfo,
      [InfoPageId.ThreeSixtyView]: AnalyticsEventPageId.ThreeSixtyInfo,
      [InfoPageId.GateReport]: AnalyticsEventPageId.GateReportInfo,
    }

    useNuxtApp().nuxt2Context.route.meta[0].pageId =
      infoPageIdsAnalyticsEventPageIdsMap[this.$route.params.page]
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
